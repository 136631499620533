import { TransformTag } from '@/utils'

export default function({ flatfinders = [] }) {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length') || get('content'),
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'section-hero',
        style: 'padding-top: 0;',
      },

      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            condition: get('media.cover.length'),
            props: {
              manualNavigation: false,
            },
          },
        ],
      },
    },

    {
      condition: get('media.cover.length') || get('content'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
        style: 'padding-top: 0;',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              gap: '1rem',
              flexDirection: 'column',
              alignItems: 'center',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: { 'text-align': 'center', style: 'padding: 2rem;' },
                  data: {
                    content: TransformTag(get('content')),
                  },
                  condition: get('content'),
                },

                {
                  condition: get('customFields.page-settings.display-lead'),
                  component: 'Button',
                  data: {
                    to: { hash: 'lead' },
                    tag: 'router-link',
                    label: this.$t('leadFormTitle'),
                    icon: ['far', 'arrow-down'],
                    theme: 'primary',
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('customFields.flatfinder.display') && flatfinders.length,
      blocks: {
        default: [
          {
            component: 'Flatfinder',
            data: {
              flatfinders,
            },
          },
          {
            component: 'Residentials',
            data: {
              project: get('id', 'Root.item'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('address.location.coordinates', 'Root.item') && this.options.displayTravelCalc,
      props: {
        width: 'small',
        class: 'travel-calc',
      },
      blocks: {
        default: [
          {
            component: 'TravelCalculator',
            props: {
              buttonOptions: {
                theme: 'secondary',
              },
            },
            data: {
              coordinates: get('address.location.coordinates', 'Root.item'),
            },
          },
        ],
      },
    },

    {
      condition: get('customFields.additional-sections.0.image.url'),
      component: 'Section',
      props: {
        width: 'large',
        id: 'section-image-text',
        class: 'section-image-text',
      },
      blocks: {
        default: [
          {
            component: 'ImageText',
            data: {
              content: get('customFields.additional-sections'),
            },
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width || 'large',
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'],
        },
      }
    }),
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Map',
            props: {
              aspectRatio: '21/9',
            },
            data: {
              ...get('address', 'Root.item'),
            },
          },
        ],
      },
      condition: get('customFields.page-settings.display-map'),
    },

    {
      condition: get('customFields.page-settings.display-lead'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '2rem 8rem',
              columns: '1:2',
            },
            blocks: {
              default: [
                {
                  component: 'Roles',
                  props: {
                    customTitle: get('customFields.lead-top-content', 'Root.item'),
                  },
                  data: {
                    content: get('roles.salesman', 'Root.item'),
                  },
                },

                {
                  component: 'Lead',
                  props: {
                    options: {
                      fields: ['!title'],
                    },
                  },
                  data: {
                    reference: {
                      ref: get('id', 'Root.item'),
                      onModel: 'Project',
                    },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content:
                            get('customFields.lead-form-content', 'Root.item') ||
                            `<h2>${this.$t('leadFormTitle')} </h2>`,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
